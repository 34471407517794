import { createRouter, createWebHistory } from 'vue-router';
import ProductsPage from '@/pages/ProductsPage.vue';
import ProductPage from '@/pages/ProductPage.vue';
import LoginPage from '@/pages/LoginPage.vue';
import ProfilePage from '@/pages/ProfilePage.vue';
import { getToken } from '@/services/tokenService';
import UsersPage from '@/pages/UsersPage.vue';

const routes = [
  { path: '/login', component: LoginPage },
  { path: '/profile', component: ProfilePage },
  { path: '/users', component: UsersPage },
  { path: '/', component: ProductsPage },
  { path: '/c/:id', component: ProductsPage },
  { path: '/p/:id', component: ProductPage },
  { path: '/search',
    name: 'SearchResults',
    component: ProductsPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = getToken();

  if (['/profile', '/users'].includes(to.path) && !token) {
    next("/login");
  } else {
    next();
  }
});

router.afterEach((to) => {
  if (typeof gtag === 'function') {
    window.gtag('config', 'GA_MEASUREMENT_ID', {
      page_path: to.fullPath,
    });
  }
});

export default router;
